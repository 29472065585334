import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import installHttp from './support/http';
import { useAuthStore } from '@/store/auth';
import { getLocalStorage } from '@/utils/localstorage';
import { STORAGE_AUTHORIZATION, STORAGE_USER } from '@/support/constants';
import type { AuthorizationResponse } from '@/types/auth';
import type { User } from '@/types/user';
import { setHttpToken } from '@/support/http';
import GoogleSignInPlugin from "vue3-google-signin"
import Vue3Toastify, {type ToastContainerOptions} from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

async function checkStoredCredentials() {
  const authStore = useAuthStore();

  // Recupera a autorização e o usuário do localStorage
  const storageAuthorization = await getLocalStorage(STORAGE_AUTHORIZATION) as AuthorizationResponse | null;
  const storageUser = await getLocalStorage(STORAGE_USER) as User | null;

  // Atualiza o estado da store de autenticação
  authStore.authorization = storageAuthorization;
  authStore.isAuthenticated = !!storageAuthorization;
  authStore.user = storageUser;

  // Define o token HTTP se houver autorização
  if (storageAuthorization) {
    setHttpToken(storageAuthorization);

    // Se houver um token válido, faz o get do profile
    await authStore.profile();
  }
}

// Função para inicializar o app após verificar credenciais
async function initApp() {
  const app = createApp(App);

  // Instalação do sistema HTTP
  installHttp(app);

  // Usa o Pinia
  app.use(createPinia());

  app.use(GoogleSignInPlugin, {
    clientId: '852240520728-40164a11qso0dhf1jrrt9mlgbmbnf7m2.apps.googleusercontent.com'
  })

  // Verifica as credenciais armazenadas
  await checkStoredCredentials();

  // Usa o router após a verificação
  app.use(router);

  app.use(Vue3Toastify, {
    theme: "light",
    position: "bottom-center",
    autoClose: 5000,
    dangerouslyHTMLString: true
  } as ToastContainerOptions);

  // Monta o app
  app.mount('#app');
}

// Chama a função para inicializar o app
initApp();
