<template>
  <div class="layout">
    <auth-layout v-if="route.meta.layout === 'auth'">
      <router-view/>
    </auth-layout>
    <default-layout v-if="route.meta.layout === 'default'">
      <router-view/>
    </default-layout>
    <interview-layout v-if="route.meta.layout === 'interview'">
      <router-view/>
    </interview-layout>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, onUnmounted, watch} from 'vue'
import {useAuthStore} from "@/store/auth";
import {useWebSocketStore} from "@/store/websocket";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import {useRoute} from "vue-router";
import AuthLayout from "@/layouts/AuthLayout.vue";
import InterviewLayout from "@/layouts/InterviewLayout.vue";

export default defineComponent({
  name: 'App',
  components: {InterviewLayout, AuthLayout, DefaultLayout},

  setup() {
    const route = useRoute()
    const authStore = useAuthStore();
    const websocketStore = useWebSocketStore();

    onMounted(async () => {
      const authorization = authStore.authorization

      // Verifica se o usuário está presente, caso sim, inicia o WebSocket
      if (authorization) {
        websocketStore.initWebSocket(authorization);
      }

      // Verifica mudanças no usuário para iniciar o WebSocket se o usuário for autenticado posteriormente
      watch(
        () => authStore.authorization,
        (newAuthorization) => {
          if (newAuthorization && !websocketStore.socket) {
            websocketStore.initWebSocket(newAuthorization);
          }
        }
      );
    });

    onUnmounted(() => {
      websocketStore.closeWebSocket();
    });

    return {
      route
    }
  },
})
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Days+One&family=Manrope:wght@200..800&family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Borel&family=Days+One&family=Manrope:wght@200..800&family=Outfit:wght@100..900&display=swap');

$color1: #DE76C0;
$color2: #EF9BE9;
$color3: #FBAFB3;

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Manrope", serif;
  font-optical-sizing: auto;
  font-size: 1.1rem;
  font-weight: 500;
  font-style: normal;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

.layout {
  height: 100%;

  .default-layout-container {

  }
}

.Toastify {
  font-size: .9rem;
}
</style>
