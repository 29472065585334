<template>
  <div :class="['item', { active: isActive }]" @click="navigate">
    <div class="wrapper">
      <div class="mark"></div>
      <div class="icon">
        <component :is="icon"/>
      </div>
      <div class="text">{{ label }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed, PropType} from 'vue';
import {useRouter, useRoute} from 'vue-router';

export default defineComponent({
  name: 'UiMenuItem',
  props: {
    label: {
      type: String,
      required: true
    },
    targetRoute: {
      type: String,
      required: true
    },
    icon: {
      type: Object as PropType<any>,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    const isActive = computed(() => route.name === props.targetRoute);

    const navigate = () => {
      router.push({name: props.targetRoute});
    };

    return {isActive, navigate};
  }
});
</script>

<style scoped>
.item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  line-height: 2.5;
  width: 100%;
  overflow: hidden;

  .mark {
    left: 4px;
    position: absolute;
    height: 0;
    width: 4px;
    background: transparent;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 2px 20px;
    border-radius: 8px;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    margin-right: 10px;
  }

  .text {
    font-size: .9rem;
    font-weight: 700;
  }

  &:hover {
    .mark {
      transition: height 200ms ease-in-out;
      background: #298AE2;
      height: 15px;
    }
    transition: all 300ms ease;
    cursor: pointer;
  }

  &.active {
    color: #298AE2;

    .mark {
      transition: height 300ms ease;
      height: 30px;
      background: #298AE2;
    }
    .wrapper {
      transition: all 300ms ease;
    }
  }


}

</style>
