import type {AxiosInstance, AxiosResponse, AxiosError} from 'axios'
import type {ErrorResponse} from '@/types/auth'
import notify from "@/utils/notify";

export default function interceptors(http: AxiosInstance): void {
  http.interceptors.response.use(
    (response: AxiosResponse) => response.data.data,
    (error: AxiosError) => {
      const {response} = error

      const defaultMessage = 'Ops. Algo de errado aconteceu...'

      if (response) {
        const errorMessage = (response.data as ErrorResponse)?.message || defaultMessage

        if (response.status === 400) {
          notify(errorMessage, {type: 'error', position: 'bottom-right'});
          console.error(errorMessage)
        }

        if (response.status === 401) {
          notify(errorMessage, {type: 'error', position: 'bottom-right'});
          console.error(errorMessage)
        }

        if (response.status === 404) {
          console.info(errorMessage)
        }

        return Promise.reject(response)
      } else {
        return Promise.reject(error)
      }
    }
  )
}
