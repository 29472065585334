<template>
  <div class="greeting-container">
    <div class="avatar">
      <template v-if="authStore.user?.avatar">
        <img :src="authStore.user?.avatar" alt="Avatar"/>
      </template>
      <template v-else>
        <div class="initials">{{ initials }}</div>
      </template>
    </div>
    <h2>Olá, {{ authStore.user?.name.split(' ')[0] }}!</h2>
    <div class="text-small">{{ authStore.user?.email }}</div>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed, PropType} from 'vue';
import {useAuthStore} from "@/store/auth";

export default defineComponent({
  name: 'UiGreeting',

  setup() {
    const authStore = useAuthStore()

    const initials = computed(() => {
      const nameParts = authStore.user?.name.split(' ');
      const firstInitial = nameParts ? nameParts[0]?.charAt(0).toUpperCase() || '' : '';
      const secondInitial = nameParts ? nameParts[1]?.charAt(0).toUpperCase() || '' : '';
      return `${firstInitial}${secondInitial}`;
    });

    return {
      authStore,
      initials
    };
  }
});
</script>

<style lang="scss" scoped>
.greeting-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  text-align: center;
}

.text-small {
  font-size: .9rem;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #555555;
  color: #ffffff;
  font-size: 32px;
  font-weight: bold;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

h2 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

p {
  font-size: 14px;
  margin-top: 8px;
}
</style>
