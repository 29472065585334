<template>
  <div class="interview-layout-container">
    <slot/>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'


export default defineComponent({
  name: 'InterviewLayout',

  components: {},

  setup() {

  },
})
</script>

<style lang="scss" scoped>
.interview-layout-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
