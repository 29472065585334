import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "default-layout-container" }
const _hoisted_2 = { class: "inner" }
const _hoisted_3 = { class: "sidebar" }
const _hoisted_4 = { class: "profile" }
const _hoisted_5 = { class: "menu" }
const _hoisted_6 = { class: "menu" }
const _hoisted_7 = { class: "item logout" }
const _hoisted_8 = { class: "wrapper" }
const _hoisted_9 = { class: "icon" }
const _hoisted_10 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_logo = _resolveComponent("ui-logo")!
  const _component_ui_menu_item = _resolveComponent("ui-menu-item")!
  const _component_icon_logout = _resolveComponent("icon-logout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ui_logo)
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item) => {
              return (_openBlock(), _createBlock(_component_ui_menu_item, {
                key: item.targetRoute,
                icon: item.icon,
                label: item.label,
                targetRoute: item.targetRoute
              }, null, 8, ["icon", "label", "targetRoute"]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_icon_logout)
              ]),
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text" }, "Desconectar", -1))
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ]))
}