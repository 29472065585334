import {defineStore} from 'pinia';
import type {AuthorizationResponse} from "@/types/auth";
import {
  DEFINE_STORE_WEBSOCKET,
  WEBSOCKET_EVENT_CLOSE,
  WEBSOCKET_EVENT_ERROR,
  WEBSOCKET_EVENT_MESSAGE,
  WEBSOCKET_EVENT_OPEN
} from "@/support/constants";

export const useWebSocketStore = defineStore(DEFINE_STORE_WEBSOCKET, {
  state: () => ({
    socket: null as WebSocket | null,
    user_private_room: null as string | null
  }),
  actions: {
    initWebSocket(authorization: AuthorizationResponse) {
      this.socket = new WebSocket(process.env.VUE_APP_WS_URL);

      this.socket.addEventListener(WEBSOCKET_EVENT_OPEN, () => {
        const auth = {
          action: "auth",
          payload: {
            token: authorization.access_token,
            browser_info: {
              application: "entreviste-link",
              agent: navigator.userAgent,
              platform: navigator.platform,
              language: navigator.language,
              vendor: navigator.vendor,
            }
          }
        }
        this.authConnection(JSON.stringify(auth))
      });

      this.socket.addEventListener(WEBSOCKET_EVENT_MESSAGE, (event) => {
        const data = JSON.parse(event.data);

        // Se falhar a autenticação
        if (data.action === "auth" && data.status === "failure") {
          console.log('Falha ao autenticar a conexão');
          this.closeWebSocket()
        }
      });

      this.socket.addEventListener(WEBSOCKET_EVENT_CLOSE, () => {
        console.log('Conexão fechada pelo servidor');
        this.closeWebSocket();
      });

      this.socket.addEventListener(WEBSOCKET_EVENT_ERROR, (event) => {
        console.error('Erro no WebSocket:', event);
        this.closeWebSocket();
      });
    },

    sendMessage(data: string) {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket?.send(data);
      } else {
        console.error('WebSocket não está aberto. Estado atual:', this.socket?.readyState);
      }
    },

    authConnection(message: string) {
      this.sendMessage(`${message}`)
    },

    joinChannel(message: string) {
      this.sendMessage(`join channel-${message}`)
    },

    leaveChannel(message: string) {
      this.sendMessage(`leave channel-${message}`)
    },

    closeWebSocket() {
      if (this.socket) {
        this.socket.close();
        this.socket = null;
      }
    }
  },
});
