import {createRouter, createWebHistory} from 'vue-router'
import {useAuthStore} from '@/store/auth';

import {
  ROUTER_NAME_CURRICULUM,
  ROUTER_NAME_HOME,
  ROUTER_NAME_INTERVIEW, ROUTER_NAME_INTERVIEWS, ROUTER_NAME_JOBS,
  ROUTER_NAME_LOGIN, ROUTER_NAME_MESSAGES, ROUTER_NAME_NOTIFICATIONS,
  ROUTER_REDIRECT_PATH,
} from "@/support/constants";
import {setLocalStorage} from "@/utils/localstorage";

const routes = [
  {
    path: `/${ROUTER_NAME_LOGIN}`,
    name: ROUTER_NAME_LOGIN,
    component: () => import('@/views/LoginView.vue'),
    props: true,
    meta: {
      layout: 'auth',
      requiresAuth: false
    }
  },
  {
    path: `/`,
    name: ROUTER_NAME_HOME,
    component: () => import('@/views/DashboardView.vue'),
    props: true,
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: `/${ROUTER_NAME_INTERVIEWS}`,
    name: ROUTER_NAME_INTERVIEWS,
    component: () => import('@/views/InterviewsView.vue'),
    props: true,
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: `/${ROUTER_NAME_MESSAGES}`,
    name: ROUTER_NAME_MESSAGES,
    component: () => import('@/views/MessagesView.vue'),
    props: true,
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: `/${ROUTER_NAME_NOTIFICATIONS}`,
    name: ROUTER_NAME_NOTIFICATIONS,
    component: () => import('@/views/NotificationsView.vue'),
    props: true,
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: `/${ROUTER_NAME_CURRICULUM}`,
    name: ROUTER_NAME_CURRICULUM,
    component: () => import('@/views/CurriculumView.vue'),
    props: true,
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: `/${ROUTER_NAME_INTERVIEW}/:workspace/:jobOpening`,
    name: ROUTER_NAME_INTERVIEW,
    component: () => import('@/components/ui/UiSipCall.vue'),
    props: true,
    meta: {
      layout: 'interview',
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  if (to.matched.some(record => record.meta.requiresAuth) && !authStore.isAuthenticated) {
    if (!to.fullPath.endsWith('login')) {
      await setLocalStorage(ROUTER_REDIRECT_PATH, {path: to.fullPath});
    }
    next({name: ROUTER_NAME_LOGIN});
  } else {
    next();
  }
});


export default router
