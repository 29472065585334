<template>
  <svg :height="size" :width="size" viewBox="0 0 24 24">
    <path clip-rule="evenodd" d="M6 2a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3zm10.293 5.293a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414L18.586 13H10a1 1 0 1 1 0-2h8.586l-2.293-2.293a1 1 0 0 1 0-1.414"
          fill="currentColor"
          fill-rule="evenodd"/>
  </svg>
</template>

<script>
export default {
  name: 'IconLogout',

  props: {
    size: {
      default: 26,
      required: false
    }
  }
}
</script>
