class ValidationErrors {
  private errors: { [key: string]: string[] };

  constructor(payload: any) {
    // Verifica se o status é 422, caso contrário, lança uma exceção
    if (payload.status !== 422) {
      throw new Error('Este não é um erro de validação.');
    }

    this.errors = payload.data.errors || {};
  }

  /**
   * Lista todos os erros.
   * @returns { { [key: string]: string[] } } - Objeto contendo todos os erros.
   */
  listAllErrors(): { [key: string]: string[] } {
    return this.errors;
  }

  /**
   * Retorna os erros de uma chave específica.
   * Se houver apenas um erro, retorna a string diretamente.
   * Se houver múltiplos erros, retorna um array de strings.
   * @param key - A chave para a qual você deseja obter os erros.
   * @returns { string | string[] | null } - Erro único, array de erros, ou null se a chave não existir.
   */
  getErrorsByKey(key: string): string | string[] | null {
    return this.errors[key];
  }

  /**
   * Remove uma chave da lista de erros.
   * @param key - A chave a ser removida.
   */
  removeKey(key: string): void {
    delete this.errors[key];
  }

  /**
   * Verifica se uma chave existe na lista de erros.
   * @param key - A chave a ser verificada.
   * @returns { boolean } - Retorna true se a chave existir, caso contrário, false.
   */
  hasKey(key: string): boolean {
    return this.errors.hasOwnProperty(key);
  }
}

export default ValidationErrors;
