import localforage from 'localforage'
import { STORAGE_PREFIX_NAME } from '@/support/constants'

localforage.config({
  driver: localforage.LOCALSTORAGE,
  name: STORAGE_PREFIX_NAME,
});

// Armazena os dados no localforage
export const setLocalStorage = async <T>(key: string, data: T): Promise<void> => {
  try {
    const storageKey = `${STORAGE_PREFIX_NAME}_${key}`;
    if (typeof data === 'object' && data !== null) {
      await localforage.setItem(storageKey, data);
    } else {
      await localforage.setItem(storageKey, data as any);
    }
  } catch (error) {
    console.error('Erro ao armazenar dados no localforage:', error);
  }
};

// Recupera os dados do localforage
export const getLocalStorage = async <T>(key: string): Promise<T | null> => {
  try {
    const storageKey = `${STORAGE_PREFIX_NAME}_${key}`;
    const item = await localforage.getItem<string | T>(storageKey);

    return item as T | null;
  } catch (error) {
    console.error('Erro ao recuperar dados do localforage:', error);
    return null;
  }
};

// Remove os dados do localforage
export const removeLocalStorage = async (key: string): Promise<void> => {
  try {
    const storageKey = `${STORAGE_PREFIX_NAME}_${key}`;
    await localforage.removeItem(storageKey);
  } catch (error) {
    console.error('Erro ao remover dados do localforage:', error);
  }
};

// Converte string JSON em objeto
export const stringToJson = (data: string): any | null => {
  try {
    return JSON.parse(data);
  } catch (error) {
    console.error('Erro ao converter string para JSON:', error);
    return null;
  }
};
