import {http} from '@/support/http';
import type {AxiosResponse} from 'axios';
import type {AuthorizationResponse, LoginCredentials, RegisterData} from "@/types/auth";
import type {User} from "@/types/user";

const prefix = "/users";

const usersApi = () => {
    return {
        profile: (): Promise<AxiosResponse<User>> => {
            return http.get<User>(`${prefix}/profile`);
        },
    };
};

export default usersApi;
