import {toast} from "vue3-toastify";
import type {ToastOptions} from "vue3-toastify";

const notify = (message: string, options?: ToastOptions) => {
  toast(message, {
    type: "default",
    position: toast.POSITION.BOTTOM_CENTER,
    ...options,
  });
};

export default notify;
