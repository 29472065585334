export const STORAGE_PREFIX_NAME = 'entreviste'

export const DEFINE_STORE_AUTH = 'auth'
export const DEFINE_STORE_WEBSOCKET = 'websocket'
export const DEFINE_STORE_DESKTOP = 'desktop'

export const STORAGE_AUTHORIZATION = 'authorization'
export const STORAGE_USER = 'user'

export const ROUTER_NAME_HOME = 'home'
export const ROUTER_NAME_MESSAGES = 'messages'
export const ROUTER_NAME_JOBS = 'jobs'
export const ROUTER_NAME_NOTIFICATIONS = 'notifications'
export const ROUTER_NAME_CURRICULUM = 'curriculum'
export const ROUTER_NAME_LOGIN = 'login'
export const ROUTER_NAME_REGISTER = 'register'
export const ROUTER_NAME_FORGOT_PASSWORD = 'forgot-password'
export const ROUTER_NAME_INTERVIEW = 'interview'
export const ROUTER_NAME_INTERVIEWS = 'interviews'

export const ROUTER_REDIRECT_PATH = 'redirect-path'

export const WEBSOCKET_EVENT_OPEN = 'open'
export const WEBSOCKET_EVENT_MESSAGE = 'message'
export const WEBSOCKET_EVENT_CLOSE = 'close'
export const WEBSOCKET_EVENT_ERROR = 'error'
