<template>
  <div class="default-layout-container">
    <div class="inner">
      <div class="sidebar">
        <div>
          <div class="profile">
            <!--            <ui-greeting />-->
            <ui-logo/>
          </div>
          <div class="menu">
            <ui-menu-item
              v-for="item in menuItems"
              :key="item.targetRoute"
              :icon="item.icon"
              :label="item.label"
              :targetRoute="item.targetRoute"
            />
          </div>
        </div>
        <div class="menu">
          <div class="item logout">
            <div class="wrapper">
              <div class="icon">
                <icon-logout/>
              </div>
              <div class="text">Desconectar</div>
            </div>
          </div>
        </div>
      </div>
      <div class="main">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import UiGreeting from "@/components/ui/UiGreeting.vue";
import IconDashboard from "@/components/icons/IconDashboard.vue";
import IconMessages from "@/components/icons/IconMessages.vue";
import IconJobs from "@/components/icons/IconJobs.vue";
import IconNotifications from "@/components/icons/IconNotifications.vue";
import IconCurriculum from "@/components/icons/IconCurriculum.vue";
import IconLogout from "@/components/icons/IconLogout.vue";
import UiMenuItem from "@/components/ui/UiMenuItem.vue";
import {
  ROUTER_NAME_CURRICULUM,
  ROUTER_NAME_HOME, ROUTER_NAME_INTERVIEWS,
  ROUTER_NAME_MESSAGES,
  ROUTER_NAME_NOTIFICATIONS
} from "@/support/constants";
import UiLogo from "@/components/ui/UiLogo.vue";


export default defineComponent({
  name: 'DefaultLayout',

  components: {
    UiLogo,
    UiMenuItem,
    IconLogout, IconCurriculum, IconNotifications, IconJobs, IconMessages, IconDashboard, UiGreeting
  },

  setup() {
    const menuItems = [
      {label: 'Dashboard', targetRoute: ROUTER_NAME_HOME, icon: IconJobs},
      {label: 'Entrevistas', targetRoute: ROUTER_NAME_INTERVIEWS, icon: IconJobs},
      {label: 'Curriculum', targetRoute: ROUTER_NAME_CURRICULUM, icon: IconCurriculum},
      {label: 'Mensagens', targetRoute: ROUTER_NAME_MESSAGES, icon: IconMessages},
      {label: 'Notificações', targetRoute: ROUTER_NAME_NOTIFICATIONS, icon: IconNotifications},
    ];


    return {
      menuItems
    };
  },
})
</script>

<style lang="scss" scoped>
.default-layout-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  color: #bcbcbc;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 2.5;
    width: 100%;

    &.logout {
      padding-bottom: 25px;
    }

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 2px 15px;
      border-radius: 8px;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      margin-right: 10px;
    }

    .text {
      font-size: .9rem;
      font-weight: 700;
    }

    &.active {
      color: #298AE2;
    }

    &:hover {
      color: #298AE2;
      cursor: pointer;
    }
  }

  .inner {
    display: flex;
    height: 100%;

    .sidebar {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 350px;
      border-right: 1px solid #EDEDED;
      padding-right: 40px;

      .profile {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 35px 0;
      }
    }

    .main {
      display: flex;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      margin-left: 40px;
    }

    .menu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .item {
        line-height: 3;
      }
    }
  }
}

/* Mobile */
@media (max-width: 480px) {
}

/* Tablets */
@media (min-width: 481px) and (max-width: 1024px) {
}

/* Desktop */
@media (min-width: 1025px) {
  .default-layout-container {
    .inner {
      width: 1440px;

      .sidebar {

      }

      .main {

      }
    }
  }
}
</style>
