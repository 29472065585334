import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "greeting-container" }
const _hoisted_2 = { class: "avatar" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "initials"
}
const _hoisted_5 = { class: "text-small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.authStore.user?.avatar)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.authStore.user?.avatar,
            alt: "Avatar"
          }, null, 8, _hoisted_3))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.initials), 1))
    ]),
    _createElementVNode("h2", null, "Olá, " + _toDisplayString(_ctx.authStore.user?.name.split(' ')[0]) + "!", 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.authStore.user?.email), 1)
  ]))
}