import {defineStore} from 'pinia';
import {ref} from 'vue';
import type {LoginCredentials, RegisterData, AuthorizationResponse} from '@/types/auth';
import type {User} from '@/types/user';
import {RouteLocationAsPathGeneric, useRouter} from "vue-router";
import {
  DEFINE_STORE_AUTH,
  ROUTER_NAME_LOGIN,
  ROUTER_REDIRECT_PATH,
  STORAGE_AUTHORIZATION,
  STORAGE_USER,
} from "@/support/constants";
import authApi from "@/api/auth";
import usersApi from "@/api/users";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage
} from "@/utils/localstorage";
import {setHttpToken} from "@/support/http";
import {LoginWithGoogle} from "@/types/auth";
import notify from "@/utils/notify";
import ValidationErrors from "@/support/validationErrors";


export const useAuthStore = defineStore(DEFINE_STORE_AUTH, () => {
  const user = ref<User | null>(null);
  const isAuthenticated = ref(false);
  const router = useRouter();
  const authorization = ref<AuthorizationResponse | null>(null);

  const profile = async () => {
    try {
      usersApi().profile().then(response => {
        const userData = response;
        setUser(response);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const redirect = async (router: any) => {
    const redirectPath = await getLocalStorage<string | RouteLocationAsPathGeneric>(ROUTER_REDIRECT_PATH) || '/';
    await removeLocalStorage(ROUTER_REDIRECT_PATH);
    await profile();
    router.push(redirectPath);
  }

  const loginUser = async (credentials: LoginCredentials, router: any) => {
    try {
      const data = await authApi().authenticate(credentials);

      setHttpToken(data);
      await setAuthorization(data);

      await redirect(router)
    } catch (error: any) {
      const validationErrors = new ValidationErrors(error);
      notify(error.data?.message || 'Ocorreu um erro', {type: 'error', position: "bottom-right"});
      return validationErrors;
    }
  };

  const loginWithGoogle = async (payload: LoginWithGoogle, router: any) => {
    try {
      const data = await authApi().authenticateWithGoogle(payload);

      setHttpToken(data);
      await setAuthorization(data);

      notify('Conectado com a conta do Google.', {type: 'success', position: 'bottom-right'});
      await redirect(router)
    } catch (error) {
      console.error(error);
    }
  };


  const register = async (payload: RegisterData) => {
    authApi().register(payload).then(() => {
      router.push({name: ROUTER_NAME_LOGIN});
    }).catch(error => {
      console.error(error)
    })
  };

  const forgotPassword = async (email: string) => {
    try {
      console.log('Redefinir link enviado para', email);
    } catch (error) {
      console.error(error);
    }
  };

  const logoutUser = async (routerInstance: any) => {
    user.value = null
    isAuthenticated.value = false
    await removeLocalStorage(STORAGE_AUTHORIZATION)
    await removeLocalStorage(STORAGE_USER)
    await routerInstance.push(ROUTER_NAME_LOGIN)
  }


  const setAuthorization = async (data: AuthorizationResponse) => {
    authorization.value = data;
    isAuthenticated.value = true;
    await setLocalStorage(STORAGE_AUTHORIZATION, data);
  };

  const setUser = async (data: any) => {
    user.value = data;
    await setLocalStorage(STORAGE_USER, data);
  };

  return {
    user,
    isAuthenticated,
    authorization,
    profile,
    setUser,
    logoutUser,
    loginUser,
    register,
    forgotPassword,
    setAuthorization,
    setLocalStorage,
    loginWithGoogle
  };
});

