import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_auth_layout = _resolveComponent("auth-layout")!
  const _component_default_layout = _resolveComponent("default-layout")!
  const _component_interview_layout = _resolveComponent("interview-layout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.route.meta.layout === 'auth')
      ? (_openBlock(), _createBlock(_component_auth_layout, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.route.meta.layout === 'default')
      ? (_openBlock(), _createBlock(_component_default_layout, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.route.meta.layout === 'interview')
      ? (_openBlock(), _createBlock(_component_interview_layout, { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}