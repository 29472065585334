import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = { class: "ui-logo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("img", {
      alt: "logo",
      src: _imports_0
    }, null, -1),
    _createElementVNode("div", { class: "name" }, "Entreviste", -1)
  ])))
}