import axios from 'axios'
import type { AxiosInstance } from 'axios'
import interceptors from './interceptors'
import type { AuthorizationResponse } from '@/types/auth'

export const http: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_CORE as string,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const setHttpToken = (token: AuthorizationResponse | null) => {
  if (!token || !token.token_type || !token.access_token) {
    http.defaults.headers.common.Authorization = ''
  } else {
    const accessToken = `${token.token_type} ${token.access_token}`
    http.defaults.headers.common.Authorization = accessToken
  }
}

export default function install (app: any): void {
  interceptors(http)
  Object.defineProperty(app.config.globalProperties, '$http', {
    get () {
      return http
    }
  })
}
