<template>
  <div class="ui-logo">
    <img alt="logo" src="@/assets/logo.png">
    <div class="name">Entreviste</div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'UiLogo',
}
</script>

<style lang="scss" scoped>
.ui-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .name {
    font-size: 2rem;
    margin-left: 10px;
    background: linear-gradient(to right, #00CAFD, #3377DA);
    background-clip: text;
    color: transparent;
    font-family: "Borel", cursive;
    font-weight: 400;
    font-style: normal;
    display: flex;
    top: 14px;
    align-items: center;
    justify-content: center;
    height: fit-content;
    position: relative;
  }
}
</style>
