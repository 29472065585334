import {http} from '@/support/http';
import type {AxiosResponse} from 'axios';
import type {AuthorizationResponse, LoginCredentials, RegisterData} from "@/types/auth";
import type {User} from "@/types/user";
import {LoginWithGoogle} from "@/types/auth";

const prefix = "/auth";

const authApi = () => {
  return {
    authenticate: (payload: LoginCredentials): Promise<AuthorizationResponse> => {
      return http.post(`${prefix}/login`, payload);
    },

    authenticateWithGoogle: (payload: LoginWithGoogle): Promise<AuthorizationResponse> => {
      return http.post(`${prefix}/google`, payload);
    },

    register: (payload: RegisterData) => {
      return http.post(`${prefix}/login`, payload);
    },

    profile: (): Promise<AxiosResponse<User>> => {
      return http.get<User>(`${prefix}/profile`);
    },
  };
};

export default authApi;
